<script setup lang="ts">
import { UserChip } from '@/enitites/event'
import { useImage } from '@/shared/composables/image'

defineProps<{
  user: UserChip
}>()

const { getAvatar } = useImage()
</script>

<template>
  <div class="user-chip">
    <VAvatar size="32">
      <VImg
        :src="getAvatar(user.avatar, 64)"
        class="avatar"
      />
    </VAvatar>

    <span class="dancer-name">{{ user.dancerName }}</span>

    <slot name="actions" />
  </div>
</template>

<style scoped lang="scss">
.user-chip {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 16px 12px;
  border-radius: var(--border-radius-rounded);
  transition: background-color 0.3s;

  &:hover {
    background-color: var(--color-grey-100);
  }

  .avatar {
    width: 32px;
    height: 32px;
  }

  .dancer-name {
    margin-right: auto;
    margin-left: 8px;
  }
}
</style>
