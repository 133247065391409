// @ts-expect-error not typed yet
import { callPrivateApi } from '@/api/apiHelper'
import {
  Organization,
  OrganizationsList,
  OrganizationUser,
  SearchParams,
  UserRole,
} from '@/modules/Organizations/types'
// TODO: Rewrite later
export const addUserWithRole = async (orgId: number, userId: number, role: UserRole) =>
  callPrivateApi<OrganizationUser[]>('post', `/orgs/${orgId}/role`, {
    userId,
    role,
  })

export const removeUserWithRole = async (orgId: number, userId: number, role: UserRole) =>
  callPrivateApi<OrganizationUser[]>('delete', `/orgs/${orgId}/role`, { data: { userId, role } })

export const fetchOrganization = async (id: number | string) => callPrivateApi<Organization>('get', `/orgs/${id}`)
export const updateOrganization = async (org: Organization) =>
  callPrivateApi<Organization>('post', `/orgs/${org.id}`, org)
export const fetchOrganizationsList = async ({
  pageNumber: p,
  pageSize: pp,
  userId: u,
  keyword: k,
}: {
  pageNumber: number
  pageSize: number
  userId?: number
  keyword?: string
}) => callPrivateApi<OrganizationsList>('get', '/orgs', { params: { p, pp, u, k } })

export const createOrganization = async () => callPrivateApi<number>('post', '/orgs')
export const fetchLeaderboard = async ({ pageNumber, pageSize, sortBy, sortDir, orgId, year }: SearchParams) => {
  const params = {
    p: pageNumber,
    pp: pageSize,
    sb: `${sortBy}-${sortDir}`,
    y: year,
  }
  return callPrivateApi<OrganizationUser[]>('get', `/orgs/${orgId}/leaderboard`, { params })
}

export const fetchOrganizationMembers = async (orgId: number, { pageNumber, pageSize, keyword }: SearchParams) => {
  const params = {
    p: pageNumber,
    pp: pageSize,
    k: keyword,
  }
  return callPrivateApi<OrganizationUser[]>('get', `/orgs/${orgId}/members`, { params })
}

export const deleteOrganizationMemberById = async (orgId: number, userId: number) =>
  callPrivateApi<string>('delete', `/orgs/${orgId}/members/${userId}`)

export const addOrganizationMember = async (orgId: number, userId: number) =>
  callPrivateApi<OrganizationUser[]>('post', `/orgs/${orgId}/members/${userId}`)
