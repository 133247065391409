import Organizations from '@/modules/Organizations/pages/OrganizationsPage.vue'
import CreateOrganization from '@/modules/Organizations/pages/CreateOrganization.vue'
import Organization from '@/modules/Organizations/pages/OrganizationPage.vue'
import OrganizationSettings from '@/modules/Organizations/pages/OrganizationSettingsPage.vue'
import OrganizationSettingsGeneral from '@/modules/Organizations/components/settings/OrganizationSettingsGeneral.vue'
import OrganizationSettingsAdmins from '@/modules/Organizations/components/settings/OrganizationSettingsAdmins.vue'
import OrganizationSettingsPromoters from '@/modules/Organizations/components/settings/OrganizationSettingsPromoters.vue'
import OrganizationSettingsSocials from '@/modules/Organizations/components/settings/OrganizationSettingsSocials.vue'
import OrganizationSettingsAgreement from '@/modules/Organizations/components/settings/OrganizationSettingsAgreement.vue'
import OrganizationSettingsWaiver from '@/modules/Organizations/components/settings/OrganizationSettingsWaiver.vue'
import OrganizationSettingsMembers from '@/modules/Organizations/components/settings/OrganizationSettingsMembers.vue'

export default [
  {
    path: '/organizations',
    name: 'Organizations',
    component: Organizations,
  },
  {
    path: '/create-organization-organization/:id',
    name: 'CreateOrganization',
    component: CreateOrganization,
  },
  {
    path: '/organization/:id',
    name: 'Organization',
    component: Organization,
  },
  {
    path: '/organization/:id/settings',
    name: 'OrganizationSettings',
    component: OrganizationSettings,
    children: [
      {
        path: 'general',
        component: OrganizationSettingsGeneral,
        name: 'OrganizationSettingsGeneral',
        meta: { title: 'General Info' },
      },
      {
        path: 'admins',
        component: OrganizationSettingsAdmins,
        name: 'OrganizationSettingsAdmins',
        meta: { title: 'Admins' },
      },
      {
        path: 'members',
        component: OrganizationSettingsMembers,
        name: 'OrganizationSettingsMembers',
        meta: { title: 'Members' },
      },
      {
        path: 'promoters',
        component: OrganizationSettingsPromoters,
        name: 'OrganizationSettingsPromoters',
        meta: { title: 'Promoters' },
      },
      {
        path: 'socials',
        component: OrganizationSettingsSocials,
        name: 'OrganizationSettingsSocials',
        meta: { title: 'Social' },
      },
      {
        path: 'agreement',
        component: OrganizationSettingsAgreement,
        name: 'OrganizationSettingsAgreement',
        meta: { title: 'Agreement' },
      },
      {
        path: 'waiver',
        component: OrganizationSettingsWaiver,
        name: 'OrganizationSettingsWaiver',
        meta: { title: 'Waiver' },
      },
    ],
  },
]
