<script lang="ts" setup>
import { Organization, OrganizationUser, SearchParams } from '@/modules/Organizations/types'
import {
  fetchOrganizationMembers,
  deleteOrganizationMemberById,
  addOrganizationMember,
} from '@/enitites/organization/api/endpoints'
import { ref, watch } from 'vue'
import UserChip from '@/shared/ui/chips/UserChip.vue'
import BKInput from '@/components/AppShared/BKInput.vue'
import { mdiMagnify, mdiMinusCircleOutline, mdiPlus } from '@mdi/js'
import { BKModal } from '@/shared/ui/modals'
import BKUserSelect from '@/components/BKUserSelect.vue'

const props = defineProps<{ org: Organization }>()

const totalMembersCount = ref(props.org.totalMembers)
const orgMembers = ref<OrganizationUser[]>([])
const infiniteId = ref(Number(new Date()))
const loading = ref(false)
const showNotFound = ref(false)
const searchParams = ref<SearchParams>({
  pageNumber: 0,
  pageSize: 100,
  keyword: '',
})

const infiniteHandler = async ($state: { complete: () => void; loaded: () => void }) => {
  loading.value = true
  const data = await fetchOrganizationMembers(props.org.id, searchParams.value)
  loading.value = false

  if (!Array.isArray(data)) {
    $state.complete()
    return
  }
  searchParams.value.pageNumber += 1

  orgMembers.value = [...orgMembers.value, ...data]
  if (!orgMembers.value.length) showNotFound.value = true
  if (data.length < searchParams.value.pageSize) $state.complete()
  else $state.loaded()
}

const searchTimeout = ref<number | null>(null)

const refreshMembers = () => {
  searchParams.value.pageNumber = 0
  orgMembers.value = []
  showNotFound.value = false
  infiniteId.value += 1
}

const showAddModal = ref(false)
const userToAdd = ref<Record<string, unknown>[]>([])
const isAddingMember = ref(false)

const handleAddUser = async () => {
  if (!userToAdd.value.length || !userToAdd.value[0].id) return
  isAddingMember.value = true

  try {
    const res = await addOrganizationMember(props.org.id, userToAdd.value[0].id as number)
    if (res !== 'ok') return

    refreshMembers()
    totalMembersCount.value += 1
  } catch (e) {
    console.error(e)
  } finally {
    isAddingMember.value = false
    showAddModal.value = false
    userToAdd.value = []
  }
}

const handleDeleteUser = async (user: OrganizationUser) => {
  const res = await deleteOrganizationMemberById(props.org.id, user.id)
  if (res !== 'ok') return

  orgMembers.value = orgMembers.value.filter((u) => u.id !== user.id)
  totalMembersCount.value -= 1
}

watch(
  () => searchParams.value.keyword,
  () => {
    if (searchTimeout.value) clearTimeout(searchTimeout.value)
    searchTimeout.value = setTimeout(() => {
      searchParams.value.pageNumber = 0
      orgMembers.value = []
      showNotFound.value = false
      infiniteId.value += 1
      searchTimeout.value = null
    }, 600)
  }
)
</script>

<template>
  <div class="members">
    <span class="editor-title">Members ({{ totalMembersCount }})</span>

    <div class="search">
      <BKInput
        v-model="searchParams.keyword"
        :append-icon="mdiMagnify"
        label="Search current members"
        autocomplete="off"
        background-color="white"
        hide-details
        outlined
        single-line
      />
    </div>

    <div class="list">
      <UserChip
        v-for="user in orgMembers"
        :key="JSON.stringify(user)"
        :user="user"
      >
        <template #actions>
          <v-icon
            class="close-icon"
            color="red"
            @click="() => handleDeleteUser(user)"
          >
            {{ mdiMinusCircleOutline }}
          </v-icon>
        </template>
      </UserChip>

      <infinite-loading
        :distance="400"
        :identifier="infiniteId"
        spinner="bubbles"
        @infinite="infiniteHandler"
      >
        <template #spinner>
          <v-progress-circular
            color="#FC7800"
            indeterminate
            size="80"
            width="10"
          />
        </template>
        <span slot="no-more" />
        <span slot="no-results" />
      </infinite-loading>
    </div>

    <div class="options">
      <v-btn
        color="var(--color-blue-800)"
        dark
        height="52"
        width="180"
        @click="showAddModal = true"
      >
        Add member
      </v-btn>
    </div>

    <BKModal
      :is-show="showAddModal"
      title="Add member"
      width="500"
      @update:isShow="() => (showAddModal = false)"
    >
      <BKUserSelect
        :select-user-chip="userToAdd"
        search-list-position="static"
        @removeUser="() => (userToAdd = [])"
        @selectUser="
          (value) => {
            userToAdd = [value]
          }
        "
      />
      <div class="options">
        <BKButton
          :disabled="!userToAdd.length || isAddingMember"
          :loading="isAddingMember"
          height="52"
          width="180"
          white-text
          @click="handleAddUser"
        >
          <VIcon>
            {{ mdiPlus }}
          </VIcon>
          Add
        </BKButton>
      </div>
    </BKModal>
  </div>
</template>

<style lang="scss" scoped>
@import '@/assets/style/mixins';

.members {
  display: grid;
  grid-template-rows: auto auto 1fr auto;
  height: 100%;

  .editor-title {
    @include font-h3;

    display: block;
    margin-bottom: 9px;
    padding: 20px 20px 0;
    font-weight: var(--font-weight-bold);

    @media (max-width: 768px) {
      @include font-body1;
    }
  }

  .search {
    padding: 0 20px 12px;
  }

  .list {
    padding: 0 20px;
    overflow: auto;
  }

  .options {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    margin-top: auto;
    padding: 20px;
    border-top: 1px solid var(--color-grey-600);
  }
}

::v-deep.options {
  display: flex;
  justify-content: flex-end;
  width: calc(100% + 40px);
  margin: 0 -20px;
  padding: 20px 20px 0;
  border-top: 1px solid var(--color-grey-600);
}
</style>
