<template>
  <!--  eslint-disable vuejs-accessibility/no-autofocus-->
  <VTextField
    ref="input"
    v-model.trim="inputValue"
    :append-icon="appendIcon"
    :append-outer-icon="appendOuterIcon"
    :autofocus="autofocus"
    :color="color"
    :dense="dense"
    :disabled="disabled"
    :error="error"
    :error-messages="errorMessages"
    :hide-details="hideDetails"
    :label="label"
    :max="max"
    :min="min"
    :placeholder="placeholder"
    :prefix="prefix"
    :prepend-inner-icon="prependIcon"
    :readonly="readonly"
    :required="required"
    :rules="rules"
    :type="type"
    autocomplete="autocomplete"
    background-color="var(--color-white)"
    class="app-input"
    outlined
    @blur="emit('blur')"
    @focus="emit('focus')"
    @input="emit('input', inputValue)"
    @keyup.prevent="emit('keyup', $event)"
    @click:append="emit('click:append')"
  >
    <template #append>
      <slot name="append" />
    </template>
  </VTextField>
  <!--  eslint-enable vuejs-accessibility/no-autofocus-->
</template>

<script lang="ts" setup>
import { ref, watch } from 'vue'
import { VTextField } from 'vuetify/lib/components'

const emit = defineEmits<{
  (e: 'blur'): void
  (e: 'focus'): void
  (e: 'input', value: string | number): void
  (e: 'keyup', value: KeyboardEvent): void
  (e: 'click:append'): void
  (e: 'update:modelValue', value: string | number): void
}>()

const props = withDefaults(
  defineProps<{
    autocomplete?: string
    value: InstanceType<typeof VTextField>['$props']['value']
    label?: string
    prefix?: string
    error?: boolean
    errorMessages?: string | string[]
    placeholder?: string
    type?: string
    readonly?: boolean
    required?: boolean
    rules?: ((value: string | number | undefined) => unknown)[]
    appendIcon?: string | (string | [string, number])[] | (new () => unknown)
    prependIcon?: string | (string | [string, number])[] | (new () => unknown)
    appendOuterIcon?: string | (string | [string, number])[] | (new () => unknown)
    hideDetails?: boolean | 'auto'
    autofocus?: boolean
    dense?: boolean
    disabled?: boolean
    color?: string
    min?: number
    max?: number | string
  }>(),
  {
    autocomplete: 'off',
    label: '',
    prefix: '',
    error: false,
    errorMessages: () => [],
    placeholder: '',
    type: 'text',
    readonly: false,
    required: false,
    rules: () => [],
    appendIcon: undefined,
    prependIcon: '',
    appendOuterIcon: '',
    hideDetails: 'auto',
    autofocus: false,
    dense: false,
    disabled: false,
    color: 'var(--color-blue-800)',
    min: 0,
    max: '',
  }
)

const inputValue = ref<string | number>(props.value)
watch(
  () => props.value,
  () => {
    inputValue.value = props.value
  }
)
watch(
  () => inputValue.value,
  () => {
    if (props.type === 'number') {
      emit('update:modelValue', inputValue.value ? parseInt(inputValue.value as string, 10) : '')
      return
    }

    emit('update:modelValue', inputValue.value)
  }
)
</script>

<style lang="scss" scoped>
@import '@/assets/style/mixins';

.app-input::v-deep {
  .v-text-field {
    &__slot {
      @include font-body1;
    }
  }

  &.v-text-field--outlined {
    border-color: var(--color-grey-600);
    border-radius: 6px;
  }
}
</style>
